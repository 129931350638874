<template>
    <div class="wrapper">
        <div class="position-relative w-100 vh-75 mb-5">
            <div class="img-fixed h-100 w-100" v-if="boda.portada && boda.portada.length>0">
                <div class="position-absolute" style="top: 0px; left: 0px; right: 0px; bottom: 0px; background-color: rgba(0, 0, 0, 0.5); z-index: 1;"></div>
                <img :src="boda.portada[0].ARCHIVO" alt="Portada" class="img-background h-100 w-100">
            </div>
            <div class="h-100 w-100" style="background-color: #6c757d;" v-else></div>
            <div class="position-absolute top-0 bottom-0 start-0 end-0" style="z-index: 2;">
                <div class="d-flex justify-content-center align-items-center text-white h-100">
                    <div class="text-center">
                        <div v-if="boda.CODIGO_CELEBRACION">
                            <h2 v-if="boda.FECHA_BODA" class="mb-0">{{boda.celebraciones[0].NOMBRE_CELEBRACION}} - {{$filters.date(boda.FECHA_BODA)}}</h2>
                            <h2 class="mb-0">{{ boda.celebraciones[0].NOMBRE_CELEBRACION || ''}} - Sin fecha</h2>
                        </div>
                        <div v-else>
                            <h2 v-if="boda.FECHA_BODA" class="mb-0">{{$filters.date(boda.FECHA_BODA)}}</h2>
                            <h2 v-else class="mb-0">Sin fecha</h2>
                        </div>
                        <h1 class="playfairDisplay display-2" v-if="boda.NOMBRE_PAREJA_2">{{boda.NOMBRE_PAREJA_1}} & {{boda.NOMBRE_PAREJA_2}}</h1>
                        <h1 class="playfairDisplay display-2" v-else>{{boda.NOMBRE_PAREJA_1}}</h1>
                    </div>
                </div>
                <div class="position-absolute start-0 end-0" style="bottom: -86px;">
                    <div class="container">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-md-4"></div>
                            <div class="col-md-4 text-center">
                                <label for="perfil" class="cursor-pointer">
                                    <div v-if="boda.perfil && boda.perfil.length>0">
                                        <img :src="boda.perfil[0].ARCHIVO" :alt="boda.NOMBRE_PAREJA_1+' y '+boda.NOMBRE_PAREJA_2" width="250" height="250" class="rounded-circle">
                                    </div>
                                    <div v-else>
                                        <img :src="ImgEmpty" :alt="boda.NOMBRE_PAREJA_1+' y '+boda.NOMBRE_PAREJA_2" width="250" height="250" class="rounded-circle">
                                    </div>
                                </label>

                                <UploadFile id="perfil" tipo="bodas-perfil" :adjuntos="perfil" :limitFile="1" accept="image/*" :codigo="codigo" class="d-none" :drop="true" />
                            </div>
                            <div class="col-md-4 text-end">
                                <UploadFile id="portadas" tipo="bodas-portadas" :adjuntos="portadas" :limitFile="1" accept="image/*" :codigo="codigo" class="d-none" :drop="true" />
                                <span style="background-color: rgba(0,0,0,.5);" class="d-inline-block rounded">
                                    <label for="portadas" class="btn btn-outline-light">
                                        <font-awesome-icon :icon="faCamera" />
                                        Foto de portada
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="pt-5">
            <h3 v-text="boda.MENSAJE_INVITADOS" class="text-center playfairDisplay"></h3>
        </div>
        
        <div class="form-row justify-content-center">
            <div class="col-lg-8">
                <div class="alert alert-warning d-flex align-items-center mb-3" role="alert" v-if="!boda.DIRECCION_ENVIO">
                    <svg class="bi flex-shrink-0 me-2" width="24" height="24"><use xlink:href="#exclamation-triangle-fill"/></svg>
                    <div>
                        Debes completar tu dirección para la entrega de tus regalos.
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                            <div>
                                <ul class="nav nav-tabs card-header-tabs">
                                    <li class="nav-item">
                                        <router-link :to="{name: 'boda.index'}" class="nav-link" exact-active-class="active">
                                            Mesa de regalos
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{name: 'boda.otorgados'}" class="nav-link" exact-active-class="active">
                                            Regalos otorgados
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{name: 'boda.invitados'}" class="nav-link" exact-active-class="active">
                                            Invitados
                                        </router-link>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <ul class="nav nav-tabs card-header-tabs">
                                    <li class="nav-item">
                                        <router-link :to="{name: 'productos.index'}" class="btn btn-primary me-2">
                                            <font-awesome-icon :icon="faPlusCircle" />
                                            Agregar productos
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{name: 'mesas.show', params: { id: boda.SLUG }}" class="btn btn-outline-primary">
                                            <font-awesome-icon :icon="faEye" />
                                            Ver como invitado
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ImgEmpty from '@/assets/img/empty-image.png'
import { faCamera, faPlusCircle, faEye } from '@fortawesome/free-solid-svg-icons'

import UploadFile from '@/components/UploadFile.vue'
export default {
    components: {
        FontAwesomeIcon,
        UploadFile
    },
    data(){
        return {
            faCamera,
            faPlusCircle,
            faEye,
            ImgEmpty,
            loadingMesa: false,
            filters: {
                mostrar_por: 0
            },
            portadas: [],
            perfil: []
        }
    },
    mounted(){
        if(this.user.CODIGO_USUARIO){
            this.getData()
        }
        
    },
    methods: {
        getData(){
            /*if(!this.codigo){
                this.$router.push({name: 'mesas.create'})
                return
            }*/
            this.loadingMesa = true
            this.$store.dispatch('getBoda', {
                codigo: this.codigo
            }).then((res) => {
                return res
            }).catch(err => {
                //this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                this.loadingMesa = false
            })
        },
    },
    computed: {
        codigo(){
            return this.user.CODIGO_BODA || -1
        },
        boda() {
            return this.$store.getters.boda || {}
        },
        user() {
            return this.$store.getters.currentUser || {}
        }
    },
    watch: {
        user(val){
            if(val.CODIGO_USUARIO){
                this.getData()
            }
        },
        'portadas':{
            handler(val){
                this.getData()
                return val
            },
            deep: true
        },
        'perfil':{
            handler(val){
                this.getData()
                return val
            },
            deep: true
        }
    }
}
</script>